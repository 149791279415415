import {
  Box,
  Button,
  Card,
  CardContent,
  TextField,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { format, startOfMonth, subMonths } from 'date-fns';

import useQueryParams from '~/hooks/useQueryParams';
import shallowEqual from '~/utils/shallowEqual';

import MissingPaymentOrdersListResults from './MissingPaymentOrdersListResult';
import OrderDeviationsListResults from './OrderDeviationsListResult';

const useStyles = makeStyles(theme => ({
  textField: {
    maxWidth: 170,
    minWidth: 125,
    marginTop: 10,
  },
  box: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
}));

const initialValues = {
  from: format(startOfMonth(subMonths(new Date(), 1)), 'yyyy-MM-dd'),
  to: '',
};

export default function DeviationsList() {
  const classes = useStyles();

  const { params, state, clear, handleChange } = useQueryParams(initialValues);

  return (
    <>
      <Card>
        <CardContent>
          <Typography variant="h5" component="h2">
            Avvikelserapport
          </Typography>
          <Typography
            variant="body2"
            component="p"
            css={`
              margin-top: ${p => p.theme.spacing(2)}px;
            `}
          >
            För att se ohanterade- och överbetalningar, se Billecta -&gt;
            Kundfakturor -&gt; Betalningar -&gt; Öppna/ohanterade betalningar.
          </Typography>
          <Typography
            variant="body2"
            component="p"
            css={`
              margin-top: ${p => p.theme.spacing(2)}px;
            `}
          >
            Avvikelser är inkommande betalningar som skiljer sig med beloppet på
            kopplad order. Orsaken är vanligtvis om kunden har betalat in för
            lite på en faktura, eller om det är en återbetalning.
          </Typography>
          <Typography
            variant="body2"
            component="p"
            css={`
              margin-top: ${p => p.theme.spacing(2)}px;
            `}
          >
            Saknade inbetalningar är ordrar som är markerade som betalda, men
            saknar en registrerad inbetalning. Detta kan ske för ordrar som
            betalats med kort där vi inte har mottagit inbetalnings-filen från
            swedbank ännu.
          </Typography>
        </CardContent>
      </Card>
      <Box className={classes.box} display="flex">
        <Box
          display="flex"
          flexWrap="wrap"
          gridAutoFlow="column"
          gridColumnGap="10px"
          marginTop="-10px"
        >
          <TextField
            className={classes.textField}
            label="Från"
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
            type="date"
            value={state.from}
            onChange={e => handleChange('from', e.target.value)}
          />
          <TextField
            className={classes.textField}
            label="Till"
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
            type="date"
            value={state.to}
            onChange={e => handleChange('to', e.target.value)}
          />
          {!shallowEqual(initialValues, state) && (
            <Button
              color="primary"
              css={({ theme }) => ({
                margin: theme.spacing(1),
                textTransform: 'none',
              })}
              onClick={clear}
            >
              <Box fontWeight="fontWeightBold">Nollställ filter</Box>
            </Button>
          )}
        </Box>
      </Box>
      <Box>
        <OrderDeviationsListResults params={params} />
        <MissingPaymentOrdersListResults params={params} />
      </Box>
    </>
  );
}
